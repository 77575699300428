<template>

	<div class="vue-box">

		<div class="header">
			<el-row>
				<el-col>
					<div class="title">{{material.name}}</div>
				</el-col>
			</el-row>
		</div>

		<div class="c-panel">
			<el-row>
				<el-col :span="8" v-if="material">
					<el-descriptions class="margin-top" size="medium" :column="1" border>
						<el-descriptions-item label="分类">{{material.categoryName}}</el-descriptions-item>
						<el-descriptions-item label="物资名称">{{material.name}}</el-descriptions-item>
						<el-descriptions-item label="物料编号">{{material.code}}</el-descriptions-item>
						<el-descriptions-item label="规格">{{material.sku}}</el-descriptions-item>
						<el-descriptions-item label="价格">{{material.price}}</el-descriptions-item>
						<el-descriptions-item label="单位">{{material.unit}}</el-descriptions-item>
						<el-descriptions-item label="数量">{{material.num}}</el-descriptions-item>
						<el-descriptions-item label="损耗量">{{material.lossNum}}</el-descriptions-item>
						<el-descriptions-item label="采购员">{{material.creatorName}}</el-descriptions-item>
						<el-descriptions-item label="到货时间">{{material.receiveDate}}</el-descriptions-item>
						<el-descriptions-item label="供货商名称">{{material.supplieName}}</el-descriptions-item>
						<el-descriptions-item label="当前状态">{{material.stateEnum}}</el-descriptions-item>
						<el-descriptions-item label="当前在库地">{{material.locationName}}</el-descriptions-item>
						<el-descriptions-item label="收货地址">{{material.receiveAddress}}</el-descriptions-item>
						<el-descriptions-item label="备注">{{material.info}}</el-descriptions-item>
					</el-descriptions>
					
				</el-col>
				<el-col :span="16" v-if="material.imageFiles">
					<div style="margin-left:80px; width:600px;">
						<el-carousel :interval="5000" arrow="always">
							<el-carousel-item v-for="item in material.imageFiles" :key="item">
								<el-image style="width:600px;" :src="item.url"></el-image>
							</el-carousel-item>
						</el-carousel>
						<div class="m_title"> 历史记录</div>
						<el-table :data="logs" height="340">
							<el-table-column prop="createTime" label="日期" width="180">
							</el-table-column>
							<el-table-column prop="creatorName" label="操作人" width="180">
							</el-table-column>
							<el-table-column prop="title" label="说明">
							</el-table-column>
							<el-table-column prop="num" label="数量">
								<template slot-scope="s">
									<span>{{s.row.typeEnum}}{{s.row.num}}</span>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				material: {},
				id: 0,
				logs: [],
				p: {
					pageSize: 15,
					page: 1,
					materialId: 0
				},
			}
		},
		created() {
			let id = this.sa_admin.params.id;
			this.sa.get("/material/find/" + id).then(res => {
				this.material = res.data;
				console.log(222222223, this.material);
				this.material.imageFiles = JSON.parse(this.material.image);
				console.log(67676, this.material.imageFiles)
			})
			this.p.materialId = id;
			this.sa.put("/material/getMaterialLog", this.p).then(res => {
				this.logs = res.data.content;
			})
		}
	}
</script>


<style scoped>
	.title {

		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #2d2d2d;
		line-height: 29px;
		padding-left: 20px;
		border-left: 3px solid #4983f4;
	}

	.header {
		margin: 20px;
	}

	.m_title {
		font-size: 16px;
		font-weight: bold;
		margin-top: 20px;
		margin-bottom: 15px;
	}
</style>
<style>
	.el-carousel__item:nth-child(2n) {
		background-color: #99a9bf;
	}

	.el-carousel__item:nth-child(2n + 1) {
		background-color: #d3dce6;
	}
</style>
